
import { Vue, Component } from "vue-property-decorator";
import PageSection from "@/components/Common/PageSection.vue";

export type ResourceType = {
  icon: string;
  name: string;
  routeName: string;
};

@Component({
  components: {
    PageSection,
  },
})
export default class Admin extends Vue {
  resources: ResourceType[] = [
    {
      icon: "it-user",
      name: "Utenti",
      routeName: "users_list",
    },
    {
      icon: "it-pin",
      name: "Lotti",
      routeName: "realms_list",
    },
    {
      icon: "it-pa",
      name: "Pubbliche Amministrazioni",
      routeName: "organizations_list",
    },
    {
      icon: "it-folder",
      name: "Aziende",
      routeName: "companies_list",
    },
    {
      icon: "it-files",
      name: "Linee di servizio",
      routeName: "services_list",
    },
    {
      icon: "it-plug",
      name: "Caricamento tracciato",
      routeName: "data_plots_list",
    },
  ];
}
